import Vue from 'vue';
// import axios from "axios"
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

export const state = () => ({
  state: {
    branchId: 0,
    branchName: '',
    accountName: '',
    plantId: 0,
    spa: false,
    booth: false,
    allowTimeChange: false,
    allowTimeChangeDetail: {
      result: 0,
      allowDay: 0,
      allowMonth: 0
    },
    allowTimeChangeService: false,
    isFullPos: false,
    showInventory: 0,
    pointSystem: 0,
    invoiceLength: 0,
    isInvoiceLeadingZero: 0,
    userGuid: 0,
    allowPastCoupon: 0
  }
});

export const mutations = {
  UPDATE_BRANCH_ID: (state, payload) => {
    Vue.set(state, 'branchId', payload);
  },
  UPDATE_ACCOUNT_NAME: (state, payload) => {
    Vue.set(state, 'accountName', payload);
  },
  UPDATE_PLANT_ID: (state, payload) => {
    Vue.set(state, 'plantId', payload);
  },
  UPDATE_SPA: (state, payload) => {
    Vue.set(state, 'spa', payload);
  },
  UPDATE_BOOTH: (state, payload) => {
    Vue.set(state, 'booth', payload);
  },
  UPDATE_ALLOW_TIME_CHANGE: (state, payload) => {
    Vue.set(state, 'allowTimeChange', payload);
  },
  UPDATE_ALLOW_TIME_CHANGE_DETAIL: (state, payload) => {
    Vue.set(state, 'allowTimeChangeDetail', payload);
  },
  UPDATE_ALLOW_TIME_CHANGE_SERVICE: (state, payload) => {
    Vue.set(state, 'allowTimeChangeService', payload);
  },
  UPDATE_IS_FULL_POS: (state, payload) => {
    Vue.set(state, 'isFullPos', payload);
  },
  UPDATE_SHOW_INVENTORY: (state, payload) => {
    Vue.set(state, 'showInventory', payload);
  },
  UPDATE_BRANCH_NAME: (state, payload) => {
    Vue.set(state, 'branchName', payload);
  },
  UPDATE_POINT_SYSTEM: (state, payload) => {
    Vue.set(state, 'pointSystem', payload);
  },
  UPDATE_INVOICE_LENGTH: (state, payload) => {
    Vue.set(state, 'invoiceLength', payload);
  },
  UPDATE_IS_INVOICE_LEADING_ZERO: (state, payload) => {
    Vue.set(state, 'isInvoiceLeadingZero', payload);
  },
  UPDATE_USER_GUID: (state, payload) => {
    Vue.set(state, 'userGuid', payload);
  },
  UPDATE_ALLOW_PAST_COUPON: (state, payload) => {
    Vue.set(state, 'allowPastCoupon', payload);
  }
};

export const actions = {
  setBranchId(context, payload) {
    context.commit('UPDATE_BRANCH_ID', payload);
  },
  setAccountName(context, payload) {
    context.commit('UPDATE_ACCOUNT_NAME', payload);
  },
  setPlantId(context, payload) {
    context.commit('UPDATE_PLANT_ID', payload);
  },
  setSpa(context, payload) {
    context.commit('UPDATE_SPA', payload);
  },
  setBooth(context, payload) {
    context.commit('UPDATE_BOOTH', payload);
  },
  setAllowTimeChange(context, payload) {
    context.commit('UPDATE_ALLOW_TIME_CHANGE', payload);
  },
  setAllowTimeChangeDetail(context, payload) {
    context.commit('UPDATE_ALLOW_TIME_CHANGE_DETAIL', payload);
  },
  setAllowTimeChangeService(context, payload) {
    context.commit('UPDATE_ALLOW_TIME_CHANGE_SERVICE', payload);
  },
  setIsFullPos(context, payload) {
    context.commit('UPDATE_IS_FULL_POS', payload);
  },
  setShowInventory(context, payload) {
    context.commit('UPDATE_SHOW_INVENTORY', payload);
  },
  setBranchName(context, payload) {
    context.commit('UPDATE_BRANCH_NAME', payload);
  },
  setPointSystem(context, payload) {
    context.commit('UPDATE_POINT_SYSTEM', payload);
  },
  setInvoiceLength(context, payload) {
    context.commit('UPDATE_INVOICE_LENGTH', payload);
  },
  setIsInvoiceLeadingZero(context, payload) {
    context.commit('UPDATE_IS_INVOICE_LEADING_ZERO', payload);
  },
  setUserGuid(context, payload) {
    context.commit('UPDATE_USER_GUID', payload);
  },
  setAllowPastCoupon(context, payload) {
    context.commit('UPDATE_ALLOW_PAST_COUPON', payload);
  }
};
export const getters = {
  getBranchId: state => {
    return state.branchId;
  },
  getAccountName: state => {
    return state.accountName;
  },
  getPlantId: state => {
    return state.plantId;
  },
  getSpa: state => {
    return state.spa;
  },
  getBooth: state => {
    return state.booth;
  },
  getAllowTimeChange: state => {
    return state.allowTimeChange;
  },
  getAllowTimeChangeDetail: state => {
    return state.allowTimeChangeDetail;
  },
  getAllowTimeChangeService: state => {
    return state.allowTimeChangeService;
  },
  getIsFullPos: state => {
    return state.isFullPos;
  },
  getShowInventory: state => {
    return state.showInventory;
  },
  getBranchName: state => {
    return state.branchName;
  },
  getPointSystem: state => {
    return state.pointSystem;
  },
  getInvoiceLength: state => {
    return state.invoiceLength;
  },
  getIsInvoiceLeadingZero: state => {
    return state.isInvoiceLeadingZero;
  },
  getUserGuid: state => {
    return state.userGuid;
  },
  getAllowPastCoupon: state => {
    return state.allowPastCoupon;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
