import Vue from 'vue'
// import axios from "axios"
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

export const state = () => ({
  state: {
    isCenterPoint: "1",
  }
})

export const mutations = {
  UPDATE_IS_CENTER_POINT: (state, payload) => {
    Vue.set(state, "isCenterPoint", payload);
  },
}

export const actions = {
  setIsCenterPoint(context, payload) {
    context.commit("UPDATE_IS_CENTER_POINT", payload);
  },
}
export const getters = {
  getIsCenterPoint: state => {
    return state.isCenterPoint;
  },
}

export default {
  state,
  actions,
  mutations,
  getters
}
