import Vue from "vue";
import App from "./App";
import router from "./router";
import editor from "@tinymce/tinymce-vue";
import CoreuiVue from "@coreui/vue";
import "./main.css";
import { i18n } from "./plugins/i18n";
import VueCookies from "vue-cookies";
import Vuelidate from "vuelidate";
import BootstrapVue from "bootstrap-vue";
import { BootstrapVueIcons, BIcon } from "bootstrap-vue";
import VueMoment from "vue-moment";
import vueNumeralFilterInstaller from "vue-numeral-filter";
import ToggleButton from "vue-js-toggle-button";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "./store";
import InfiniteLoading from "vue-infinite-loading";
import alert from "./alert";
import swal2 from "sweetalert2";

const moment = require("moment");
require("moment/locale/th");
import "bootstrap-vue/dist/bootstrap-vue.css";

import "tinymce/tinymce";

// Theme
import "tinymce/themes/silver/theme";

// Skins
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/content/default/content.min.css";

// Plugins
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/paste";
import "tinymce/plugins/autoresize";

import "babel-polyfill";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { Settings as LuxonSettings } from "luxon";

//Icon
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEyeSlash,
  faPowerOff,
  faUser,
  faStore,
  faBullhorn,
  faSearch,
  faFileUpload,
  faFilter,
  faSortDown,
  faPencilAlt,
  faTrashAlt,
  faTimes,
  faTimesCircle,
  faFileDownload,
  faEye,
  faBarcode,
  faBars,
  faPlus,
  faMinus,
  faStar,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faHome,
  faBuilding,
  faHotel,
  faCircle,
  faChevronCircleLeft,
  faArrowRight,
  faClipboard,
  faBox,
  faSort,
  faHandHoldingUsd,
  faPlusCircle,
  faMinusCircle,
  faCheckCircle,
  faExclamationCircle,
  faEllipsisV,
  faCheck,
  faMapMarkerAlt,
  faMapMarkedAlt,
  faStickyNote,
  faShippingFast,
  faMoneyBillWave,
  faReceipt,
  faUserCheck,
  faUserPlus,
  faPercent,
  faRobot,
  faFileExport,
  faUserFriends,
  faCommentAlt,
  faSmile,
  faAngry,
  faMobileAlt,
  faWindowMaximize,
  faMap,
  faImage,
  faSave,
  faPlusSquare,
  faCog,
  faCalendarAlt,
  faChevronCircleRight,
  faChevronCircleDown,
  faSyncAlt,
  faGift,
  faEdit,
  faFilePdf,
  faPrint
} from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarFar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  fab,
  faFacebookF,
  faInstagram,
  faLine,
  faTwitter,
  faApple,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
library.add(
  fab,
  faFacebookF,
  faInstagram,
  faLine,
  faTwitter,
  faApple,
  faYoutube
);
library.add(
  faEyeSlash,
  faUser,
  faPowerOff,
  faStore,
  faBullhorn,
  faSearch,
  faFilter,
  faFileUpload,
  faSortDown,
  faPencilAlt,
  faTrashAlt,
  faTimes,
  faTimesCircle,
  faFileDownload,
  faEye,
  faBarcode,
  faBars,
  faPlus,
  faMinus,
  faStar,
  faStarFar,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faHome,
  faBuilding,
  faHotel,
  faCircle,
  faChevronCircleLeft,
  faArrowRight,
  faClipboard,
  faBox,
  faSort,
  faHandHoldingUsd,
  faPlusCircle,
  faMinusCircle,
  faCheckCircle,
  faExclamationCircle,
  faEllipsisV,
  faCheck,
  faMapMarkerAlt,
  faMapMarkedAlt,
  faStickyNote,
  faShippingFast,
  faMoneyBillWave,
  faReceipt,
  faUserCheck,
  faUserPlus,
  faPercent,
  faRobot,
  faFileExport,
  faUserFriends,
  faCommentAlt,
  faSmile,
  faAngry,
  faMobileAlt,
  faWindowMaximize,
  faMap,
  faImage,
  faSave,
  faPlusSquare,
  faCog,
  faCalendarAlt,
  faChevronCircleRight,
  faChevronCircleDown,
  faSyncAlt,
  faGift,
  faEdit,
  faFilePdf,
  faPrint
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.use(VueAxios, axios);
Vue.use(vueNumeralFilterInstaller, { locale: "en-gb" });
Vue.use(VueCookies);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component("BIcon", BIcon);

Vue.use(ToggleButton);
Vue.use(Vuelidate);
Vue.use(VueMoment, {
  moment,
});
Vue.component("datetime", Datetime);
Vue.component("editor", editor);

Vue.use(InfiniteLoading, {
  /* options */
});

let globalData = new Vue({
  data: {
    $isLoading: false,
    $campaignName: "",
  },
});

Vue.use({
  // this is the required "install" method for Vue plugins
  install(Vue) {
    Vue.swal2 = swal2;
    Vue.prototype.$swal2 = swal2;
  },
});

Vue.mixin({
  computed: {
    $isLoading: {
      get: function () {
        return globalData.$data.$isLoading;
      },
      set: function (isLoading) {
        globalData.$data.$isLoading = isLoading;
      },
    },
  },
});

// const MODE = process.env.VUE_APP_MODE;
Vue.prototype.$adminUrl = process.env.VUE_APP_ADMIN_URL;
Vue.prototype.$baseTheme = process.env.VUE_APP_BASE_THEME;
Vue.prototype.$baseUrl = process.env.VUE_APP_BASE_URL;
Vue.prototype.$baseUrlSaleOrder = process.env.VUE_APP_BASE_URL_SALE_ORDER;
Vue.prototype.$formatDate = "DD MMM YYYY";
Vue.prototype.$formatTime = "HH:mm";
Vue.prototype.$formatDateTime = "DD MMM YYYY (HH:mm:ss)";
Vue.prototype.$formatDateNewFull= "DD/MM/YYYY HH:mm:ss";
Vue.prototype.$formatDateNew = "DD/MM/YYYY";
Vue.prototype.$formatTimeNew = "HH:mm:ss";

// API request
Vue.axios.interceptors.request.use(function (config) {
  //check timestamp
  var timestamp = Vue.$cookies.get("timestamp");
  if (timestamp && store.getters.getTimestamp()) {
    if (timestamp > store.getters.getTimestamp()) {
      window.location.href = "/";
    }
  }

  config.headers.common["Authorization"] = Vue.$cookies.isKey("token")
    ? `Bearer ${Vue.$cookies.get("token")}`
    : "null";
  config.headers.common["API-KEY"] = process.env.VUE_APP_API_KEY;
  config.headers.common["BrandId"] =
    store.getters.getCurrentBrandID() == null
      ? 0
      : store.getters.getCurrentBrandID();
  return config;
});
// API response
Vue.axios.interceptors.response.use(
  function (response) {
    return Promise.resolve(response.data);
  },
  function (error) {
    if (error.response.status == 401) {
      Vue.$cookies.remove("token");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

LuxonSettings.defaultLocale = "th";

router.beforeEach((to, from, next) => {
  // var dOrderUrl = `${process.env.VUE_APP_D_ORDER_URL}?brandID=${from.query.brandID}`
  // var dChatbotUrl = `${process.env.VUE_APP_D_CHATBOT_URL}?brandID=${from.query.brandID}`

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!VueCookies.get("token")) {
      next({
        path: "/login",
      });
    } else {
      // if (!to.query.brandID) {
      //   if (from.query.brandID) {
      //     if (to.params.id) {
      //       next({
      //         name: to.name,
      //         query: { brandID: from.query.brandID, ...to.query },
      //         params: { id: to.params.id, ...to.params },
      //       });
      //     } else {
      //       next({
      //         name: to.name,
      //         query: { brandID: from.query.brandID, ...to.query },
      //       });
      //     }
      //     return;
      //   } else {
      //     next();
      //   }
      // } else {
        next();
      // }
    }
  } else if (to.matched.some((record) => record.meta.requiresIsLogin)) {
    if (VueCookies.get("token")) {
      next({
        path: "/",
      });
    } else {
      next();
    }
  } else {
    if (to.name == "d-order") {
      // window.open(dOrderUrl)
    } else if (to.name == "d-chatbot") {
      // window.open(dChatbotUrl)
    } else {
      next();
    }
  }
});

new Vue({
  el: "#app",
  library,
  i18n,
  router,
  alert,
  store,
  template: "<App/>",
  components: {
    App,
  },
});
